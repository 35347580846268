import React from "react";
import Carousel from "../../../components/Carroussel";

export default function Empresas() {
  const empresas = [
    { nome: "RZK Energia", logoUrl: "/images/home/empresas/logo-rzk.svg" },
    {
      nome: "Yellow Energy",
      logoUrl: "/images/home/empresas/logo-yellow-energy.svg"
    },
    { nome: "Claro", logoUrl: "/images/home/empresas/logo-claro.svg" },
    {
      nome: "Ética Energia",
      logoUrl: "/images/home/empresas/logo-etica-energia.svg"
    },
    { nome: "Oi", logoUrl: "/images/home/empresas/logo-oi.svg" },
    {
      nome: "Mato Grosso Energia",
      logoUrl: "/images/home/empresas/logo-mato-grosso-energia.svg"
    },
    { nome: "TIM", logoUrl: "/images/home/empresas/logo-tim.svg" },
    { nome: "Amee", logoUrl: "/images/home/empresas/logo-amee.svg" },
    {
      nome: "Bom Futuro",
      logoUrl: "/images/home/empresas/logo-bom-futuro.svg"
    },
    { nome: "Safira", logoUrl: "/images/home/empresas/logo_safira_padrao.png" },
    {
      nome: "BC Energia",
      logoUrl: "/images/home/empresas/bc_energia_logo.png"
    },
    { nome: "BRZ", logoUrl: "/images/home/empresas/logo-brz.svg" }
  ];

  return (
    <>
      <div className="px-6 lg:px-14 2xl:px-48 py-24 ">
        <h2 className="text center text-zinc-600 text-xl md:text-2xl font-medium font-bai leading-10 text-center mb-8">
          Algumas empresas que confiam na Acesso Energia
        </h2>
        <Carousel empresas={empresas} />
      </div>
    </>
  );
}
