import React from "react";

export default function SobreAcesso() {
  return (
    <>
      <section
        className="px-6 lg:px-14 2xl:px-48 py-10 flex items-center justify-center mb-20 mt-10"
        id="porque-acesso"
      >
        <div>
          <div className="flex justify-center flex-col md:flex-row gap-10 md:gap-20">
            <div className="flex flex-col gap-3 md:w-1/2">
              <div className="flex flex-col justify-start items-start gap-4 md:gap-8">
                <h2 className=" text-zinc-800 text-4xl md:text-5xl font-bold font-bai leading-snug">
                  Por que a Acesso?
                </h2>
                <div className="w-72 h-2 relative bg-green-600 rounded-lg" />
              </div>
              <div className="flex flex-col justify-center items-start gap-3 mt-5">
                <div className="p-7 bg-green-950 rounded-xl flex-col justify-start items-start gap-1 md:gap-3 flex">
                  <h3 className="text-white text-2xl md:text-3xl font-bold font-bai leading-10">
                    Tecnologia
                  </h3>
                  <p className="text-zinc-200 text-sm md:text-base font-normal font-roboto leading-relaxed">
                    Desenvolvida com inovação desde o princípio, nossa
                    plataforma proporciona automação e economia para suas
                    operações no mercado livre e cativo, além da Gestão da
                    Geração e Geração Distribuída de energia.
                  </p>
                </div>
                <div className="flex justify-start items-center gap-3">
                  <div className="grow shrink basis-0 self-stretch p-7 bg-green-900 rounded-xl flex-col justify-start items-start gap-1 md:gap-3 inline-flex">
                    <h3 className="self-stretch text-white text-2xl md:text-3xl font-bold font-bai leading-relaxed">
                      Experiência
                    </h3>
                    <p className="self-stretch text-zinc-200 text-sm md:text-base font-normal font-roboto leading-relaxed">
                      Diversos anos de prática em operações de Energia e
                      Tecnologia.
                    </p>
                  </div>
                  <div className="grow shrink basis-0 p-7 bg-green-800 rounded-xl flex-col justify-start items-start gap-1 md:gap-3 inline-flex">
                    <h3 className="self-stretch text-white text-2xl md:text-3xl font-bold font-bai leading-relaxed">
                      Pioneirismo
                    </h3>
                    <p className="self-stretch text-zinc-200 text-sm md:text-base font-normal font-roboto leading-relaxed">
                      Colaboração com diversas instituições no lançamento de
                      soluções inovadoras.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <picture className="flex justify-center md:w-1/2">
              <img
                src="/images/mandala.svg"
                alt="Imagem que mostra planilhas, documentos e emails que se tornam uma plataforma completa"
                className=""
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      </section>
    </>
  );
}
