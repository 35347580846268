import React, { useState } from "react";
import Carousel from "../../../components/Carroussel";
import Modal from "../../../components/ui/Dialog";

export default function Distribuidoras() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const empresas = [
    {
      nome: "RZL Energia",
      logoUrl: "/images/home/distribuidoras/logo-ceb.svg"
    },
    {
      nome: "Yellow Energy",
      logoUrl: "/images/home/distribuidoras/logo-celgd.svg"
    },
    { nome: "Claro", logoUrl: "/images/home/distribuidoras/logo-celpe.svg" },
    {
      nome: "Ética Energia",
      logoUrl: "/images/home/distribuidoras/logo-cemar.svg"
    },
    { nome: "Oi", logoUrl: "/images/home/distribuidoras/logo-cemig.svg" },
    {
      nome: "Mato Grosso Energia",
      logoUrl: "/images/home/distribuidoras/logo-coelba.svg"
    },
    { nome: "TIM", logoUrl: "/images/home/distribuidoras/logo-copel.svg" },
    { nome: "Amee", logoUrl: "/images/home/distribuidoras/logo-cpfl.svg" },
    { nome: "Bom Futuro", logoUrl: "/images/home/distribuidoras/logo-dme.svg" },
    {
      nome: "Bom Futuro",
      logoUrl: "/images/home/distribuidoras/logo-edpes.svg"
    },
    {
      nome: "Bom Futuro",
      logoUrl: "/images/home/distribuidoras/logo-elektro.svg"
    },
    {
      nome: "Bom Futuro",
      logoUrl: "/images/home/distribuidoras/logo-eletropaulo.svg"
    },
    {
      nome: "Bom Futuro",
      logoUrl: "/images/home/distribuidoras/logo-elfsm.svg"
    },
    { nome: "Bom Futuro", logoUrl: "/images/home/distribuidoras/logo-emg.svg" },
    {
      nome: "Bom Futuro",
      logoUrl: "/images/home/distribuidoras/logo-light.svg"
    }
  ];

  const companies = [
    { name: "AMAZONAS DISTRIBUIDORA DE ENERGIA S.A.", state: "AM" },
    { name: "AMPLA ENERGIA E SERVICOS S.A.", state: "RJ" },
    { name: "CEB DISTRIBUICAO S.A.", state: "DF" },
    { name: "CELESC DISTRIBUICAO S.A.", state: "SC" },
    { name: "CELG DISTRIBUICAO S.A.", state: "GO" },
    { name: "CEMIG DISTRIBUICAO S.A.", state: "MG" },
    { name: "CENTRAIS ELETRICAS DE CARAZINHO SA", state: "RS" },
    { name: "COMPANHIA CAMPOLARGUENSE DE ENERGIA COCEL", state: "PR" },
    { name: "COMPANHIA DE ELETRICIDADE DO AMAPA", state: "AP" },
    { name: "COMPANHIA DE ELETRICIDADE DO ESTADO DA BAHIA", state: "BA" },
    { name: "COMPANHIA ENERGETICA DE PERNAMBUCO", state: "PE" },
    { name: "COMPANHIA ENERGETICA DO CEARA", state: "CE" },
    { name: "COMPANHIA ENERGETICA DO MARANHAO", state: "MA" },
    { name: "COMPANHIA ENERGÉTICA DO PIAUI", state: "PI" },
    { name: "COMPANHIA ENERGÉTICA DO RIO GRANDE DO NORTE", state: "RN" },
    {
      name: "COMPANHIA ESTADUAL DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
      state: "RS"
    },
    { name: "COMPANHIA HIDROELÉTRICA SÃO PATRÍCIO", state: "GO" },
    { name: "COMPANHIA JAGUARI DE ENERGIA", state: "RS" },
    { name: "COMPANHIA LUZ E FORCA SANTA CRUZ", state: "RS" },
    { name: "COMPANHIA PAULISTA DE FORCA E LUZ", state: "SP" },
    { name: "COMPANHIA PIRATININGA DE FORCA E LUZ", state: "SP" },
    { name: "COMPANHIA SUL PAULISTA DE ENERGIA", state: "SP" },
    { name: "COMPANHIA SUL SERGIPANA DE ELETRICIDADE", state: "SE" },
    { name: "COOP ELETRIF RURAL ITAI PARAN AVARE LTDA", state: "SP" },
    { name: "COOPERATIVA ALIANCA", state: "SC" },
    { name: "COOPERATIVA DE DISTRIBUICAO DE ENERGIA", state: "RS" },
    {
      name: "COOPERATIVA DE DISTRIBUICAO DE ENERGIA ELETRICA SANTA MARIA",
      state: "RS"
    },
    {
      name: "COOPERATIVA DE DISTRIBUICAO DE ENERGIA ENTRE RIOS LTDA",
      state: "RS"
    },
    {
      name: "COOPERATIVA DE DISTRIBUICAO E GERACAO DE ENERGIA DAS MISSOES",
      state: "RS"
    },
    { name: "COOPERATIVA DE ELETRICIDADE DE SAO LUDGERO", state: "SC" },
    {
      name:
        "COOPERATIVA DE ELETRICIDADE E DESENVOLVIMENTO DA REGIAO DE MOGI MIRIM",
      state: "SP"
    },
    { name: "COOPERATIVA DE ELETRICIDADE GRAO PARA", state: "SC" },
    { name: "COOPERATIVA DE ELETRIFICACAO ANITA GARIBALDI", state: "SC" },
    { name: "COOPERATIVA DE ELETRIFICACAO CENTRO JACUI LTDA", state: "RS" },
    { name: "COOPERATIVA DE ELETRIFICACAO DE BRACO DO NORTE", state: "SC" },
    { name: "COOPERATIVA DE ELETRIFICACAO DE IBIUNA E REGIAO", state: "SP" },
    {
      name:
        "COOPERATIVA DE ELETRIFICAÇÃO E DESENVOLVIMENTO DA REGIÃO DE SÃO JOSÉ DO RIO PRETO",
      state: "SP"
    },
    {
      name: "COOPERATIVA DE ENERGIZACAO E DE DESENVOLVIMENTO DO VALE DO MOGI",
      state: "SP"
    },
    {
      name: "COOPERATIVA DE GERAÇÃO DE ENERGIA E DESENVOLVIMENTO",
      state: "RS"
    },
    {
      name: "COOPERATIVA DISTRIBUIDORA DE ENERGIA FRONTEIRA NOROESTE",
      state: "RS"
    },
    { name: "COOPERATIVA ENERGETICA COCAL", state: "SC" },
    {
      name: "COOPERATIVA FUMACENSE DE ELETRICIDADE - CERMOFUL ENERGIA",
      state: "SC"
    },
    { name: "COOPERATIVA PIONEIRA DE ELETRIFICACAO", state: "SC" },
    {
      name: "COOPERATIVA REGIONAL DE ELETRIFICACAO RURAL DO ALTO URUGUAI",
      state: "RS"
    },
    {
      name: "COOPERATIVA REGIONAL DE ENERGIA E DESENVOLVIMENTO IJUI LTDA",
      state: "RS"
    },
    { name: "COOPERATIVA REGIONAL DE ENERGIA TAQUARI JACUI", state: "RS" },
    {
      name: "COOPERZEM COOPERATIVA DE DISTRIBUICAO DE ENERGIA ELETRICA",
      state: "MG"
    },
    { name: "COPEL DISTRIBUICAO S.A.", state: "PR" },
    { name: "DEPARTAMENTO MUNICIPAL DE ENERGIA DE IJUI", state: "RS" },
    { name: "DISTRIBUIDORA CATARINENSE DE ENERGIA ELETRICA LTDA", state: "SC" },
    { name: "DME DISTRIBUICAO S.A. - DMED", state: "MG" },
    { name: "EDP ESPIRITO SANTO DISTRIBUICAO DE ENERGIA S.A.", state: "ES" },
    { name: "EDP SAO PAULO DISTRIBUICAO DE ENERGIA S.A", state: "SP" },
    { name: "ELEKTRO REDES S.A.", state: "SP" },
    {
      name: "ELETROPAULO METROPOLITANA ELETRICIDADE DE SAO PAULO S.A.",
      state: "SP"
    },
    { name: "EMPRESA FORCA E LUZ DE URUSSANGA LTDA", state: "SC" },
    { name: "EMPRESA FORCA E LUZ JOAO CESA LTDA", state: "SC" },
    { name: "EMPRESA LUZ E FORCA SANTA MARIA S A", state: "RS" },
    { name: "ENERGISA ACRE DISTRIBUIDORA DE ENERGIA S.A.", state: "AC" },
    { name: "ENERGISA BORBOREMA - DISTRIBUIDORA DE ENERGIA S.A.", state: "PB" },
    { name: "ENERGISA MATO GROSSO DISTRIBUIDORA DE ENERGIA S.A.", state: "MT" },
    {
      name: "ENERGISA MATO GROSSO DO SUL DISTRIBUIDORA DE ENERGIA S.A.",
      state: "MS"
    },
    { name: "ENERGISA MINAS GERAIS", state: "MG" },
    { name: "ENERGISA NOVA FRIBURGO", state: "RJ" },
    { name: "ENERGISA PARAIBA DISTRIBUIDORA DE ENERGIA S.A", state: "PB" },
    { name: "ENERGISA RONDONIA - DISTRIBUIDORA DE ENERGIA S.A.", state: "RO" },
    { name: "ENERGISA SERGIPE DISTRIBUIDORA DE ENERGIA S.A.", state: "SE" },
    { name: "ENERGISA SUL-SUDESTE", state: "Sul-Sudeste" },
    { name: "ENERGISA SUL-SUDESTE - SP", state: "SP" },
    { name: "ENERGISA TOCANTINS DISTRIBUIDORA DE ENERGIA S.A.", state: "TO" },
    { name: "EQUATORIAL ALAGOAS DISTRIBUIDORA DE ENERGIA S.A.", state: "AL" },
    { name: "EQUATORIAL PARA DISTRIBUIDORA DE ENERGIA S.A.", state: "PA" },
    { name: "HIDROPAN DISTRIBUICAO DE ENERGIA S.A.", state: "RS" },
    { name: "LIGHT SERVICOS DE ELETRICIDADE S.A.", state: "RJ" },
    { name: "MUXFELDT MARIN E CIA LTDA", state: "NE" },
    { name: "RGE SUL DISTRIBUIDORA DE ENERGIA S.A.", state: "RS" },
    { name: "RORAIMA ENERGIA", state: "RR" }
  ];

  return (
    <>
      <div className="px-6 lg:px-14 2xl:px-48 py-24 ">
        <h2 className="text center text-zinc-600 text-xl md:text-2xl font-medium font-bai leading-10 text-center mb-8">
          Algumas das Distribuidoras suportadas atualmente
        </h2>
        <Carousel empresas={empresas} />
        <div className="flex items-center justify-center z-50 mt-10">
          <button
            className={`  relative inline-flex group items-center justify-center py-2.5 px-6 bg-grayDark  hover:bg-grayDark text-white rounded text-base font-nunito font-bold outline-none focus:ring-4  transform active:scale-x-75 transition-transform border `}
            onClick={openModal}
          >
            <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-md group-hover:w-full group-hover:h-10 opacity-10"></span>
            Todas as distribuídoras
          </button>

          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            title="Todas as distribuídoras"
            description="Caso não tenha sua distribuida em nossa lista, entre em contato conosco!"
          >
            <div className="relative w-full overflow-h-auto mt-5">
              <table className="w-full caption-bottom text-sm">
                <thead className="border-b">
                  <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                    <th className="h-10 px-2 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]">
                      Nome da Distribuídora
                    </th>
                    <th className="h-10 px-2 align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px] text-center">
                      Estado
                    </th>
                  </tr>
                </thead>
                <tbody className="[&_tr:last-child]:border-0">
                  {companies.map((company, index) => (
                    <tr
                      key={index}
                      className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted"
                    >
                      <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]">
                        {company.name}
                      </td>
                      <td className="p-2 text-center align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]">
                        {company.state}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
