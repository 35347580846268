import { Transition } from "@headlessui/react";
import React from "react";
import { PiXBold } from "react-icons/pi";
import { useMediaQuery } from "react-responsive";

const Modal = ({ isOpen, onClose, children, title, description, footer }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const handleContactClick = () => {
    onClose();
    window.scrollBy({
      top: isTabletOrMobile ? 5000 : 600,
      left: 0,
      behavior: "smooth"
    });
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <div className="fixed inset-0 overflow-y-auto z-50">
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity">
              <div
                className="absolute inset-0 bg-gray-500 opacity-75"
                onClick={onClose}
              ></div>
            </div>
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative z-10 mx-auto bg-white rounded-lg w-1/2 gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg">
              <div className="flex justify-between items-start">
                <div className="flex flex-col gap-2">
                  <h2 className="font-bai text-2xl font-bold leading-none tracking-tight text-neutral-700">
                    {title}
                  </h2>
                  <p className="font-roboto text-base text-neutral-500">
                    {description}
                  </p>
                </div>
                <button
                  className="text-gray-500 hover:text-gray-700 focus:outline-none"
                  onClick={onClose}
                >
                  <PiXBold width={32} />
                </button>
              </div>
              <div className="mt-2 max-h-[50vh] overflow-x-auto">
                {children}
              </div>
              <div className="mt-5 flex justify-end">
                <button
                  onClick={handleContactClick}
                  className={`  relative inline-flex group items-center justify-center py-2.5 px-6 bg-grayDark  hover:bg-grayDark text-white rounded-lg text-sm font-nunito font-bold outline-none focus:ring-4  transform active:scale-x-75 transition-transform border `}
                >
                  <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-md group-hover:w-full group-hover:h-10 opacity-10"></span>
                  Entre em contato conosco
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};

export default Modal;
