import React, { lazy, Suspense, useEffect, useState } from "react";
import Nav from "../../components/NavSecondary";
import ScrollToTop from "../../components/ScrollToTop";
import "../../utils/scroll.css";
import Distribuidoras from "./components/Distribuidoras";
import Empresas from "./components/Empresas";
import SobreAcesso from "./components/SobreAcesso";

const HeaderHome = lazy(() => import("./components/HeaderHome"));
const SolucoesHome = lazy(() => import("./components/SolucoesHome"));
const NumerosHome = lazy(() => import("./components/NumerosHome"));
const Contato = lazy(() => import("../../components/Contato"));
const Footer = lazy(() => import("../../components/Footer"));
const renderLoader = () => (
  <></>
  // <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hiddenopacity-75 flex flex-col items-center justify-center">
  //   <img
  //     src="/images/logo/logo-acesso-p.svg"
  //     className="mb-3 w-20 h-20"
  //     alt="Logo Acesso"
  //   />
  //   <div className="relative w-1/6  bg-gray-200 rounded">
  //     <div
  //       style={{ width: "100%" }}
  //       className="absolute top-0 h-3 rounded shim-green"
  //     ></div>
  //   </div>
  //   <h2 className="pt-2 text-center text-gray-700 text-lg font-semibold">
  //     Energizando...
  //   </h2>
  // </div>
);

export default function Home() {
  useEffect(() => {
    document.title = "Faça a gestão de energia do seu negócio | Acesso Energia";
  }, []);

  const [email, setEmail] = useState("");

  return (
    <Suspense fallback={renderLoader()}>
      <div className="scrollbar h-screen">
        <ScrollToTop />

        <div className="bg-home min-h-screen  bg-center relative">
          <img
            src="/images/bloob-verde.svg"
            alt=""
            style={{ animationDelay: "6000ms" }}
            className="blur-3xl mix-blend-lighten animate-blob absolute bottom-60 right-80 -z-0 select-none w-1/3 h-1/3"
          />
          <img
            src="/images/bloob-azul.svg"
            alt=""
            style={{ animationDelay: "5000ms" }}
            className="blur-3xl mix-blend-lighten animate-blob absolute top-40 left-10 z-0 select-none w-1/2 h-1/2"
          />
          <img
            src="/images/bloob-amarelo.svg"
            alt=""
            style={{ animationDelay: "4000ms" }}
            className="blur-3xl mix-blend-lighten animate-blob absolute animation-delay-2000 top-40 left-40 z-0 select-none w-1/3 h-1/3"
          />
          <img
            src="/images/bloob-roxo.svg"
            alt=""
            style={{ animationDelay: "2000ms" }}
            className="blur-3xl mix-blend-lighten animate-blob absolute animation-delay-5000 top-40 right-40 z-0 select-none w-1/2 h-1/2"
          />
          <img
            src="/images/bloob-verde-pequeno.svg"
            alt=""
            style={{ animationDelay: "5000ms" }}
            className="blur-3xl mix-blend-lighten animate-blob absolute animation-delay-2000 duration-500 bottom-60 left-72 z-0 select-none w-1/4 h-1/4"
          />
          <div className="z-50">
            <Nav />
            <HeaderHome currentEmail={setEmail} />
          </div>
        </div>
        <SolucoesHome />
        <Empresas />
        <NumerosHome />
        <SobreAcesso />
        <Distribuidoras />
        <Contato email={email} />
        <Footer />
      </div>
    </Suspense>
  );
}
