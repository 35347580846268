import React, { useEffect, useRef } from "react";

const Carousel = ({ empresas, duplicacoes = 10 }) => {
  // Duplica a lista
  const empresasDuplicadas = [...empresas];
  for (let i = 1; i < duplicacoes; i++) {
    empresasDuplicadas.push(...empresas);
  }

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const scroll = () => {
      if (container) {
        container.scrollLeft += 1;
        if (
          container.scrollLeft >=
          container.scrollWidth - container.clientWidth
        ) {
          container.scrollTo({
            left: 0,
            behavior: "smooth"
          });
        }
      }
      requestAnimationFrame(scroll);
    };
    scroll();
    return () => cancelAnimationFrame(scroll);
  }, []);

  return (
    <div ref={containerRef} className="overflow-hidden w-full">
      <div className="flex items-center gap-12 md:gap-20 scroll-smooth">
        {empresasDuplicadas.map((empresa, index) => (
          <div key={index} className="flex-shrink-0 p-2">
            <img
              src={empresa.logoUrl}
              alt={empresa.nome}
              className={`${
                empresa.nome === "RZK Energia"
                  ? "h-auto w-18 "
                  : empresa.nome === "TIM"
                  ? "h-6 w-auto mt-1 "
                  : "h-8 w-auto "
              }  object-contain`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
